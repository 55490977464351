import "core-js/modules/es.array.push.js";
import { getActivePage, releaseAdminActive, getOperatorsList } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      goodsCodeValue: [],
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        active_type: '',
        current: 1,
        fabu_state: '',
        total: 0
      },
      operatorList: [],
      operatorIds: [],
      exportIds: '',
      checkMuneSer: [],
      user: []
    };
  },
  mounted() {
    this.getOperatorsList();
    this.getList();
    this.user = JSON.parse(localStorage.getItem('user')) || null;
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.active_type = '';
      this.pageInfo.fabu_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    getList() {
      getActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getOperatorsList() {
      getOperatorsList().then(res => {
        this.operatorList = res.data;
      });
    },
    showEdit(id, x) {
      if (x == 1) {} else {
        x = '';
      }
      this.$root.useRouter.push({
        path: "/admin/activeManage/activeGoodsEdit",
        query: {
          id: id,
          is_info: x
        }
      });
    },
    showCode(id) {
      this.$root.useRouter.push({
        path: "/admin/activeCode/activeCodeList",
        query: {
          id: id
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    showNotice(id) {
      this.dialogFormVisible = true;
      this.active_id = id;
    },
    subTeacherNotice() {
      let that = this;
      this.$root.ElMessageBox.confirm('注意：指定运营商发布后，活动产品的所有将不能修改，你确定要继续操作吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        releaseAdminActive({
          active_id: that.active_id,
          operatorIds: that.operatorIds
        }).then(res => {
          that.$root.msgResult(res, '', that, 'getList');
          that.dialogFormVisible = false;
        });
      });
    }
  }
};